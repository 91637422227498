<template>
    <div class="optimization-page">

      <back-to-top :custom-style="myBackToTopStyle" :visibility-height="300" :back-position="0" transition-name="fade" />
      <Footer/>
    </div>
</template>

<script>
import Footer from "@/components/Footer.vue";
export default {
  name:"Optimization",
  data() {
    return {
      myBackToTopStyle: {
        right: '50px',
        bottom: '50px',
        width: '6rem',
        height: '6rem',
        'border-radius': '50%',
        'line-height': '45px', // �뱣����߶�һ���Դ�ֱ����
      },
    };
  },
  components: {
    Footer
  }
}
</script>

<style lang="less" scoped>

</style>
